import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "fransvilhelmhooks"
    }}>{`@fransvilhelm/hooks`}</h1>
    <p>{`A set of reusable hooks to use inside react components.`}<br parentName="p"></br>{`
`}<a parentName="p" {...{
        "href": "https://hooks.fransvilhelm.com/"
      }}>{`Documentation and examples`}</a></p>
    <h2 {...{
      "id": "installation"
    }}>{`Installation`}</h2>
    <p>{`The package can be installed using `}<a parentName="p" {...{
        "href": "https://www.npmjs.com"
      }}>{`npm`}</a>{` or
`}<a parentName="p" {...{
        "href": "https://yarnpkg.com"
      }}>{`yarn`}</a>{` or any other node package manager.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`npm install @fransvilhelm/hooks
# or with yarn
yarn add @fransvilhelm/hooks
`}</code></pre>
    <p><strong parentName="p">{`NOTE:`}</strong>{` This packages uses
`}<a parentName="p" {...{
        "href": "https://reactjs.org/docs/hooks-intro.html"
      }}>{`React Hooks`}</a>{` and those where
releases in `}<inlineCode parentName="p">{`react@16.8.0`}</inlineCode>{`. Therefore you must use a version of react that's
greater than that.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <p>{`This package contains a set of named exports and no default export is provided.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`// Use named imports to import a separate hook:
import { useInput, useCheckbox } from '@fransvilhelm/hooks';
// Or import everything:
import * as hooks from '@fransvilhelm/hooks';
`}</code></pre>
    <p><em parentName="p">{`This package is distributed as an ESM package and if you use a modern
javascript bundler (eg. Webpack, Rollup or Parcel) the source code will be
tree-shaked and only the hooks you use will be included in your final bundle.`}</em></p>
    <h2 {...{
      "id": "typescript"
    }}>{`Typescript`}</h2>
    <p>{`This package was originally written in Typescript and types are included by
default.`}</p>
    <h2 {...{
      "id": "contribute"
    }}>{`Contribute`}</h2>
    <p>{`Contributions are more than welcome! But I also urge you to create your own
versions. These hooks are hooks that I've found that I use a lot and therefore I
started to collect them inside a repo so that I can easily use them in my
projects. You should do the same!`}</p>
    <h2 {...{
      "id": "license"
    }}>{`License`}</h2>
    <p>{`MIT`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      